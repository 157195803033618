.container {
  display: flex;
  flex: 1;
  position: relative;
}

.room-container {
  display: flex;
  flex: 1;
}

.classroom {
  .room-container {
    display: flex;
    flex: 1;

    .whiteboard {
      flex: 1;
    }

    .live-board {
      display: flex;
      flex-direction: column;
      flex: 1;
      border-left: 1px solid #DBE2E5;
      max-width: 281px;
      box-sizing: border-box;
      height: 100%;

      .video-board {
        user-select: none;
        display: flex;
        flex-direction: column;
        max-height: 318px;
        box-sizing: border-box;
      }

      .chat-board {
        background-color: #FFFFFF;
        box-sizing: border-box;
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    }
  }
}